@import "~tabler-ui/src/assets/scss/_variables.scss";

$primary: $purple;
$input-focus-border-color: $primary;

@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_root.scss';
@import '~bootstrap/scss/_reboot.scss';
@import '~bootstrap/scss/_type.scss';
// @import '~bootstrap/scss/_images.scss';
// @import '~bootstrap/scss/_code.scss';
@import '~bootstrap/scss/_grid.scss';
@import '~bootstrap/scss/_tables.scss';
@import '~bootstrap/scss/_forms.scss';
@import '~bootstrap/scss/_buttons.scss';
// @import '~bootstrap/scss/_transitions.scss';
// @import '~bootstrap/scss/_dropdown.scss';
// @import '~bootstrap/scss/_button-group.scss';
@import '~bootstrap/scss/_input-group.scss';
@import '~bootstrap/scss/_custom-forms.scss';
// @import '~bootstrap/scss/_nav.scss';
// @import '~bootstrap/scss/_navbar.scss';
@import '~bootstrap/scss/_card.scss';
// @import '~bootstrap/scss/_breadcrumb.scss';
// @import '~bootstrap/scss/_pagination.scss';
@import '~bootstrap/scss/_badge.scss';
// @import '~bootstrap/scss/_jumbotron.scss';
// @import '~bootstrap/scss/_alert.scss';
@import '~bootstrap/scss/_progress.scss';
@import '~bootstrap/scss/_media.scss';
// @import '~bootstrap/scss/_list-group.scss';
// @import '~bootstrap/scss/_close.scss';
// @import '~bootstrap/scss/_toasts.scss';
@import '~bootstrap/scss/_modal.scss';
// @import '~bootstrap/scss/_tooltip.scss';
// @import '~bootstrap/scss/_popover.scss';
// @import '~bootstrap/scss/_carousel.scss';
// @import '~bootstrap/scss/_spinners.scss';
@import '~bootstrap/scss/_utilities.scss';
@import '~bootstrap/scss/_print.scss';

@import '~tabler-ui/src/assets/scss/dashboard/functions.scss';

@import '~tabler-ui/src/assets/scss/dashboard/core.scss';
@import '~tabler-ui/src/assets/scss/dashboard/type.scss';
@import '~tabler-ui/src/assets/scss/dashboard/grid.scss';

@import '~tabler-ui/src/assets/scss/dashboard/layout.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/aside.scss';
@import '~tabler-ui/src/assets/scss/dashboard/header.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/footer.scss';
@import '~tabler-ui/src/assets/scss/dashboard/colors.scss';
@import '~tabler-ui/src/assets/scss/dashboard/text.scss';
@import '~tabler-ui/src/assets/scss/dashboard/utilities.scss';

// @import '~tabler-ui/src/assets/scss/dashboard/nav.scss';
@import '~tabler-ui/src/assets/scss/dashboard/button.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/alert.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/close.scss';
@import '~tabler-ui/src/assets/scss/dashboard/badge.scss';
@import '~tabler-ui/src/assets/scss/dashboard/tables.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/breadcrumb.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/pagination.scss';
@import '~tabler-ui/src/assets/scss/dashboard/cards.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/popover.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/dropdown.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/list.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/list-group.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/avatar.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/product.scss';
@import '~tabler-ui/src/assets/scss/dashboard/progress.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/icon.scss';
@import '~tabler-ui/src/assets/scss/dashboard/image.scss';
@import '~tabler-ui/src/assets/scss/dashboard/link.scss';
@import '~tabler-ui/src/assets/scss/dashboard/media.scss';
@import '~tabler-ui/src/assets/scss/dashboard/form.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/sparkline.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/social.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/maps.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/statuses.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/charts.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/chips.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/stamp.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/chat.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/example.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/tag.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/syntax.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/infobox.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/carousel.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/datatables.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/bottombar.scss';

@import '~tabler-ui/src/assets/scss/dashboard/forms/custom-range.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/forms/custom-selectgroup.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/forms/custom-switch.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/forms/custom-imagecheck.scss';
// @import '~tabler-ui/src/assets/scss/dashboard/forms/custom-colorinput.scss';

@import '~tabler-ui/src/assets/scss/dashboard/timeline.scss';

@import './feather.scss';

svg {
  touch-action: auto;
}

a:not(.btn-purple):not(.dropdown-item) {
  color: #495057 !important;
}

a:not(.btn-purple):not(.dropdown-item):hover {
  color: #42265e !important;
}

.btn:focus {
  box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.15);
}

.dropdown-item:active {
  color: #16181b;
  background-color: #f8f9fa;
}

.dropdown-item.active {
  color: #fff !important;
  background-color: #a55eea;
}

.selectgroup-input:checked + .selectgroup-button {
  border-color: #a55eea;
  color: #a55eea;
  background: #f6effd;
}

.selectgroup-input:focus + .selectgroup-button {
  border-color: #a55eea;
  color: #a55eea;
  box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.25);
}

.leaflet-container {
  background: white !important;
}

.opacity-0 {
  opacity: 0;
}
